import React, {useState, useCallback} from 'react';
import {reducersState} from '../../redux/reducers';
import {useDispatch, useSelector} from 'react-redux';
import {logIn} from '../../redux/actions/user';
import {Link} from 'react-router-dom';
import {Loader} from '../../components';
import {isValidEmail} from '../../constants';
import Pikado from '../../assets/icons/PikaDo-logo-Final-3 3.png';
import pass_icon from '../../assets/icons/pwicon.svg';
import mail_icon from '../../assets/icons/mail.svg';

const appStyle = {
  height: '850px',
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
};

const formStyle = {
  margin: 'auto',
  padding: '10px',
  border: '1px solid #c9c9c9',
  borderRadius: '5px',
  background: '#f5f5f5',
  width: '320px',
  display: 'block',
  marginTop: 100,
  alignSelf: 'center',
};

const labelStyle = {
  margin: '10px 0 5px 0',
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: '15px',
};

const inputStyle: any = {
  margin: '5px 0 10px 0',
  padding: '5px',
  border: '1px solid #bfbfbf',
  borderRadius: '3px',
  boxSizing: 'border-box',
  width: '100%',
};

const submitStyle: any = {
  margin: '10px 0 0 0',
  padding: '7px 10px',
  border: '1px solid #efffff',
  borderRadius: '3px',
  background: '#3085d6',
  width: '100%',
  fontSize: '15px',
  color: 'white',
  display: 'block',
};

const SignIn = () => {
  const isLoading: boolean = useSelector(
    (state: reducersState) => state.user.isLoadingRequest,
  );
  const [data, setData] = useState<{
    email: string;
    password: string;
    isValidEmail: boolean;
    isValidPass: boolean;
  }>({
    email: '',
    password: '',
    isValidEmail: true,
    isValidPass: true,
  });
  const dispatch = useDispatch();

  const validateInputs = () => {
    if (data.email.trim() != '' && data.password != '') {
      setData({
        ...data,
        isValidEmail: isValidEmail(data.email),
        isValidPass: data.password.length >= 6,
      });
      return data.password.length >= 6 && isValidEmail(data.email);
    }
    return false;
  };
  const handleSubmit = async () => {
    if (validateInputs()) {
      dispatch(logIn(data.email, data.password));
    }
  };
  const updateData = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  }, []);
  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center bg-white">
      <div className="login-card p-3">
        <div className="pt-2">
          <i className="bi bi-bootstrap"></i>
          <img src={Pikado} alt="Pikado Logo" className="login-logo" />
          <h4 className="my-4 fw-bolder">Sign in to your account</h4>
        </div>
        <form>
          <div className="mb-3">
            <label className="form-label fs-6">
              Your email{' '}
              {!data.isValidEmail && (
                <span className="text-danger"> * Invalid Email</span>
              )}{' '}
            </label>

            <div className="input-group">
              <input
                name="email"
                onChange={updateData}
                type="text"
                className="form-control"
                placeholder="Enter E-Mail"
              />
              <div className="input-group-text">
                <img src={mail_icon} alt="Email Icon" />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="form-label fs-6">Your password </label>
            {!data.isValidPass && (
              <p className="text-danger text-xs">
                Password must be at least 6 characters
              </p>
            )}
            <div className="input-group">
              <input
                name="password"
                type="password"
                onChange={updateData}
                className="form-control"
                placeholder="Enter Password"
              />
              <div className="input-group-text">
                <img src={pass_icon} alt="Password Icon" />
              </div>
            </div>
          </div>
        </form>
        <div className="d-grid">
          <button
            onClick={handleSubmit}
            type="submit"
            className="auth-btn mt-4"
            disabled={data.email == '' || data.password == ''}>
            Sign in
          </button>
        </div>
        <div className="d-grid">
          <Link
            style={submitStyle}
            className="auth-btn mt-3 classera-btn"
            to={{
              pathname:
                window.location.hostname.includes('staging') ||
                window.location.hostname.includes('localhost')
                  ? 'https://me-stg.classera.com/AuthWithClassera?partnerCode=PIKADO&typeLogin=teacher'
                  : 'https://me.classera.com/AuthWithClassera?partnerCode=PikaDo&typeLogin=teacher',
            }}
            target="_parent">
            Sign In with Classera
          </Link>
        </div>
      </div>
      {isLoading && <Loader title="Loading" />}
    </div>
  );
};

export default SignIn;
