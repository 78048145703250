export const getGradeByScore = (score: number) => {
  const gradeThresholds = [
    {min: 90, grade: 'A+', color: '#C3E56E'},
    {min: 85, grade: 'A', color: '#FFD252'},
    {min: 80, grade: 'A-', color: '#40B2F2'},
    {min: 75, grade: 'B+', color: '#EF426B'},
    {min: 70, grade: 'B', color: '#F772FA'},
    {min: 65, grade: 'C+', color: '#D7D7D7'},
    {min: 60, grade: 'C', color: '#B4B4B4'},
    {min: 55, grade: 'D+', color: '#808080'},
    {min: 50, grade: 'D', color: '#2F2F2F'},
    {min: 40, grade: 'E', color: '#D9D9D9'},
  ];
  const gradeInfo = gradeThresholds.find((item) => score >= item.min);
  if (!gradeInfo) {
    return {min: 0, grade: 'F', color: '#D9D9D9'};
  }
  return gradeInfo;
};

// Helper function to validate email addresses
export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
