import React, {useState} from 'react';
import {Image, Typography} from 'antd';
import Boy from '../../assets/icons/Boy02.svg';
import {getGradeByScore} from '../../constants';
import {Classschool, School, Statistic, Teacher} from '../../graphql/API';
import AddHomeworkModal from '../../components/AddHomeworkModal/AddHomeworkModal';

const {Text} = Typography;

type ReportHeaderProps = {
  teacher?: Teacher;
  school: School;
  selectedClass: Classschool;
  report: Statistic;
};

export const ReportHeader: React.FC<ReportHeaderProps> = ({
  teacher,
  school,
  selectedClass,
  report,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <div className="row w-100 mx-auto mx-0 gx-2 flex-grow-1 justify-content-center container-fluid px-0">
      <div
        className={`col-12 col-xl-4 d-flex justify-content-center align-items-center border-bottom ps-0 ${
          window.innerWidth >= 992 ? '' : ''
        }`}>
        <div className="h-100 px-0 d-flex justify-content-center flex-shrink-0">
          <div
            style={{
              maxWidth: 95,
              width: '95px',
              aspectRatio: '1/1',
              backgroundImage: `url(${
                teacher?.profilePicture?.thumbnailUri
                  ? teacher?.profilePicture?.thumbnailUri
                  : Boy
              })`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}>
            <Image preview={false} />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center flex-grow-1 ms-3">
          <Text
            style={{
              display: 'block',
              fontFamily: 'Roboto',
              fontSize: 21,
              fontWeight: 'bold',
            }}>
            {teacher ? 'Mr. ' + teacher?.name : selectedClass?.name}
          </Text>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Roboto',
              color: '#999999',
            }}>
            {teacher && school
              ? school.name
              : `Students in Class ${selectedClass.students.length}`}
          </Text>
        </div>
      </div>
      <div
        className={`col-12 col-xl-4 d-flex justify-content-start align-items-center border-bottom flex-nowrap ${
          window.innerWidth >= 992 ? 'border-end' : 'border-0 border-bottom'
        }`}>
        <div className="h-100 px-0 d-flex justify-content-center flex-shrink-0">
          <div
            className="position-relative"
            style={{
              maxWidth: 95,
              width: '95px',
              aspectRatio: '1/1',
              backgroundColor: getGradeByScore(
                selectedClass.statistic?.averageScore
                  ? selectedClass.statistic?.averageScore
                  : 0,
              ).color,
            }}>
            <p
              style={{
                color: '#FFFFFF',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 25,
                fontFamily: 'Roboto',
              }}>
              {
                getGradeByScore(
                  selectedClass.statistic?.averageScore
                    ? selectedClass.statistic?.averageScore
                    : 0,
                ).grade
              }
            </p>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center flex-grow-1 ms-3">
          <Text
            style={{
              fontFamily: 'Roboto',
              display: 'block',
              fontSize: 16,
              color: '#999999',
              fontWeight: 'normal',
            }}>
            PERFORMANCE
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: 32,
              color: '#40B2F2',
              fontWeight: 'bold',
            }}>
            {report?.averageScore}%
          </Text>
        </div>
      </div>

      <div
        className={`col-12 col-xl-4 d-flex justify-content-center align-items-center border-end border-bottom ${
          window.innerWidth <= 992 && 'border-start'
        }`}>
        <button
          className={`btn w-75 mx-auto btn-lg btn-info text-white rounded-0 fw-bold ${
            window.innerWidth <= 992 && 'my-3'
          }`}
          onClick={() => setIsModalVisible(true)}>
          Add Homework
        </button>
        <AddHomeworkModal
          teacher={teacher}
          classSchool={selectedClass.id}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      </div>
    </div>
  );
};
