import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@fontsource/roboto';
import './assets/scss/style.scss';
//@ts-ignore
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import {withAuthenticator} from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import {PersistGate} from 'redux-persist/es/integration/react';

import './App.css';
import {Layout} from 'antd';
import {persistor, store} from './redux/store';

import {Provider, useDispatch} from 'react-redux';
import {Routers} from './router/Routers';
const {Content, Footer} = Layout;

Amplify.configure(awsconfig);

function App() {
  useEffect(() => {}, []);

  return (
    <div style={{height: '100%'}}>
      <Router>
        <Provider store={store}>
          {/*@ts-ignore*/}
          <PersistGate loading={null} persistor={persistor}>
            <Layout>
              <Content>
                <Switch>
                  <Route
                    render={() => {
                      return <Routers />;
                    }}
                  />
                </Switch>
              </Content>
            </Layout>
          </PersistGate>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
