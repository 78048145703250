import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Typography as TypographyAnt} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
// import AdbIcon from '@mui/icons-material/Adb';
import Boy from '../../assets/Boy02.svg';
import icon_top from '../../assets/icons/icon_top.svg';
import ToggleIcon from '../../assets/icons/toggle_.svg';
import Logout from '../../assets/icons/logout.svg';
import Pikado from '../../assets/icons/PikaDo-logo-Final-3 3.png';
import PikadoMobile from '../../assets/icons/PikaDoLogoMobile.png';

import './appBar.css';

import {Link, useHistory} from 'react-router-dom';

import {Loader} from '../Loader';

import ArrowDown from '../../assets/icons/down-arrow.svg';
import {SettingsModal} from '../SettingsModal';
import {Enrollment, Status, Student} from '../../graphql/API';
import {reducersState} from '../../redux/reducers';
import {signOut} from 'aws-amplify/auth';

const settings = ['Sign out'];

const {Text} = TypographyAnt;

function ResponsiveAppBar() {
  const dispatch = useDispatch();

  const history = useHistory();

  const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false);

  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    React.useState<boolean>(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const currentUser = useSelector(
    (state: reducersState) => state.user.currentUser,
  );

  const partnerUser = useSelector(
    (state: reducersState) => state.user.partnerUser,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = async (index: number) => {
    setAnchorElNav(null);

    console.log('SSS');
    if (index === 0) {
      await signOut();
      dispatch({type: 'RESET_USER'});
      history.go(0);
    } else if (index === 1) {
    }
  };

  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottomWidth: 1,
        borderBottomColor: '#F1F1F1',
        borderBottomStyle: 'solid',
        zIndex: 1000,
      }}
      color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}} /> */}

          <img
            className="pikado-image border-1 border-end pe-4 ps-0"
            alt="logo"
            src={Pikado}
            style={{
              resize: 'inline',
              // marginInlineEnd: 20,
              width: '12%',
            }}
          />

          <img
            className="pikado-image-mobile"
            alt="s"
            src={PikadoMobile}
            style={{
              resize: 'inline',
              width: '12%',
            }}
          />
          <div className="welcome-container">
            <Text
              className="welcome-title fw-bold"
              style={{
                display: 'inline-block',
                fontFamily: 'Roboto',
                marginLeft: 10,
              }}>
              {`Welcome,  ${
                currentUser?.school?.name ?? currentUser?.teacher?.school?.name
              }`}
            </Text>
          </div>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}></Box>

          <Box className="rounded">
            <Tooltip title="Home">
              <Link to={'/home'}>
                <IconButton
                  size="medium"
                  aria-label="report"
                  color="inherit"
                  className="rounded">
                  <Typography
                    textAlign="center"
                    // variant="h6"
                    noWrap
                    style={{
                      fontSize: 16,
                      color: '#40B2F2',
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      marginInline: '10px',
                    }}
                    // href="#app-bar-with-responsive-menu"
                  >
                    <span className="report-title">Home</span>
                  </Typography>
                </IconButton>
              </Link>
            </Tooltip>
          </Box>
          <Box sx={{flexGrow: 0}} className="rounded-circle">
            <Tooltip title="settings" className="rounded-circle">
              <IconButton
                onClick={handleOpenNavMenu}
                sx={{p: 0}}
                className="rounded-circle">
                <Avatar
                  alt="s"
                  src={ArrowDown}
                  sx={{width: 35, height: 35}}
                  className="rounded-circle"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{mt: '45px'}}
              id="menu-settings-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => {
                setAnchorElNav(null);
              }}
              className="rounded-circle">
              {settings.map((s: string, index: number) => (
                <MenuItem key={s} onClick={(e) => handleCloseNavMenu(index)}>
                  <Typography
                    textAlign="center"
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 14,
                      color: '#000',
                      //   fontWeight: 600,
                    }}>
                    {index === 0 ? (
                      <img
                        src={ToggleIcon}
                        style={{
                          display: 'inline-block',
                          width: 20,
                          height: 16,
                          marginInlineEnd: 7,
                        }}
                      />
                    ) : (
                      <img
                        src={Logout}
                        style={{
                          display: 'inline-block',
                          width: 20,
                          height: 16,
                          marginInlineEnd: 7,
                        }}
                        onClick={() => {}}
                      />
                    )}
                    {s}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{flexGrow: 0}}>
            <Tooltip title="profiles">
              <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                {/* <Avatar alt="" {...stringAvatar(selectedStudent?.name)} /> */}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => {
                setAnchorElUser(null);
              }}>
              {/* {studentsDetails.map((s: Student) => (
                <MenuItem
                  key={s.id}
                  onClick={() => handleCloseUserMenu(s)}
                  disabled={selectedStudent?.id === s.id}>
                  <Typography textAlign="center">{s?.name}</Typography>
                </MenuItem>
              ))} */}
            </Menu>
          </Box>
        </Toolbar>
        {isSettingsModalVisible && (
          <SettingsModal
            closeModal={() => {
              console.log('SSS,');
              setIsSettingsModalVisible(false);
            }}
          />
        )}
      </Container>

      {isLoadingData ? <Loader title={'Loading...'} /> : null}
    </AppBar>
  );
}
export default ResponsiveAppBar;
