export const fullReport = /* GraphQL */ `
  query Statistic($id: String!) {
    statistic(id: $id) {
      id
      createdAt
      updatedAt
      scores
      averageScore
      fullReportLastGeneratedDate
      phoneticsAsString
      syllablesAsString

      monthlyReports {
        id
        createdAt
        updatedAt
        month
        average
        mistakes
        attempts
        totalScores
        totalAverage
        absenceDays
        numOfStudentsInClass
        absenceDays
        d1
        d2
        d3
        d4
        d5
        d6
        d7
        d8
        d9
        d10
        d11
        d12
        d13
        d14
        d15
        d16
        d17
        d18
        d19
        d20
        d21
        d22
        d23
        d24
        d25
        d26
        d27
        d28
        d29
        d30
        d31
      }
    }
  }
`;
export const minimalStudent = /* GraphQL */ `
  query Student($id: String!) {
    student(id: $id) {
      id
      name
      age
      createdAt
      user {
        id
        country
      }
      statistic {
        id
        scores
        averageScore
        fullReportLastGeneratedDate
        phoneticsAsString
        syllablesAsString
      }
    }
  }
`;

export const customGetSyllablesByLetters = /* GraphQL */ `
  mutation GetSyllablesByLetters($lettersList: [String]) {
    getSyllablesByLetters(lettersList: $lettersList) {
      items {
        id
        createdAt
        updatedAt
        letters
        correctSyllable {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
          title_i18n
          subTitle_i18n
          position
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const getAnswer = /* GraphQL */ `
  query Answer($id: String!) {
    answer(id: $id) {
      id
      createdAt
      updatedAt
      audioUri
      score
    }
  }
`;

export const studentsTable = /* GraphQL */ `
  query Students(
    $filter: StudentsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    students(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        statistic {
          id
          createdAt
          updatedAt
          averageScore
          monthlyReports {
            id
            createdAt
            updatedAt
            month
            average
            absenceDays
            attempts
            performanceRate
            absenceRate
          }
        }
        name
      }
    }
  }
`;
// Custom query to get the students from school  
/**
 * GraphQL query to fetch the arrangement of students in a school.
 *
 * This query retrieves information about a specific school identified by its ID.
 * It includes details about the classes within the school and the students enrolled in those classes.
 * For each student, it fetches their statistics, including average scores and monthly reports.
 *
 * @query School
 * @param {String} id - The unique identifier of the school.
 * 
 * @returns {Object} The structure of the response from the GraphQL server:
 *   @returns {Object} school - The school object.
 *     @returns {Array} classesschool - The list of classes in the school.
 *       @returns {String} classesschool.id - The unique identifier of the class.
 *       @returns {Array} classesschool.students - The list of students in the class.
 */
export const studentsArrangement = /* GraphQL */ `
  query School($id: String!) {
    school(id: $id) {
      classesschool {
      id
      students{
          id
        createdAt
        updatedAt
        statistic {
          id
          createdAt
          updatedAt
          averageScore
          monthlyReports {
            id
            createdAt
            updatedAt
            month
            average
            absenceDays
            attempts
            performanceRate
            absenceRate
          }
        }
        name
      }
    }}
  }
`;
